<script>
export default {
  data: function () {
    return {
    }
  },
  methods: {
    transView: function (view, mode, validator = null) {
      if (mode == 'next') {
        if (validator != null) {
          validator.validate().then((res) => {
            if (res == true) {
              this.processTransView(view)
            }
          })
	}
	else {
          this.processTransView(view)
	}
      }
      else if (mode == 'back') {
        this.processTransView(view)
      }
    },
    processTransView: function (view) {
      this.$set(this.$data, 'currentView', view)
      window.scrollTo(0, 0)
    },
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
    }
  }
}
</script>
