<script>
export default {
  data: function () {
    return {}
  },
  methods: {
    subValidatedEmail: function (str) {
      let results = str

      if (results) {
        let regexp = new RegExp('[^\x01-\x7E]+?', 'g')
        results = results.replace(regexp, '')
      }

      return results
    },
    productSerialNumberRegex: function (serialNumber) {
      const patternBase = '[\\da-zA-Z]'
      const pattern = new RegExp('^(?:' + patternBase + '{8}|' + patternBase + '{10})$')

      if (serialNumber.match(pattern)) {
        return true
      }
      else {
        return false
      }
    }
  }
}
</script>
