<template>
<div>
<loading :active.sync="isLoading"></loading>
<Header />
<component v-on:transView="transView" v-bind:is="currentView" />
<Footer />
</div>
</template>

<script>
import Footer from './footer'
import Form from './form'
import Header from './header'
import Loading from 'vue-loading-overlay'
import LoginIndex from './login/index'

export default {
  data: function () {
    return {
      currentView: 'LoginIndex',
      isLoading: false,
      form: {}
    }
  },
  mixins: [Form],
  components: {
    Footer,
    Header,
    Loading,
    LoginIndex
  }
}
</script>
