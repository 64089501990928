import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import App from '../components/login.vue'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

document.addEventListener('DOMContentLoaded', () => {
  const el = '#app'
  const app = new Vue({
    el,
    render: h => h(App)
  })
})
