<template>
<main class="lp">
  <section class="content">
    <div class="container">
      <p class="lead txt_center">マジェスティのクラブをご購入し、ご登録された方だけが入会できる<br class="pc">マジェスティ オーナーズクラブの会員限定のサイトになります。</p>
      <p class="lead txt_center">既に、会員のご登録がお済みの方はログイン（メールアドレスとパスワード入力）をしてください。<br>まだ会員登録がお済みでない方は「新規会員登録」ボタンより、<br class="pc">ご登録をお願いします（マジェスティ のクラブをお持ちの方のみ登録可能）。</p>
    </div>
  </section>
  <section class="content">
	  <div class="container">
    <div class="item_login">
      <form>
<ValidationObserver ref="observer" v-slot="{valid}">
        <div class="ttl">
          <div class="icon"><img src="/assets/images/common/icon_h.svg"></div>
          <h2>ログイン</h2>
        </div>
        <div class="item">
<ValidationProvider name="email" rules="email-required|email-email" v-slot="{ errors }" mode="lazy">
          <input name="id" placeholder="メールアドレス" type="text" v-model="$root.$children[0].form.email" @click="initGlobalErrorMessage" @blur="processSubValidatedEmail()">
</ValidationProvider>
        </div>
        <div class="item">
<ValidationProvider name="password" rules="password-required" v-slot="{ errors }" mode="lazy">
          <input type="password" name="pwd" placeholder="パスワード" v-model="$root.$children[0].form.password" @click="initGlobalErrorMessage">
</ValidationProvider>
        </div>
    		  			<div v-if="($refs['observer'] && $refs['observer']['flags']['invalid']) || globalErrorMessage" class="txt_error"><p>{{ errorMessage }}</p></div>

        <div class="btnLogin">
          <button type="button" class="arrow" @click="submit">ログイン</button>
            <p><a href="https://www.majesty-golf.com/jp/contact/">※メールアドレスをお忘れの方</a></p>
            <p><a href="/login/pw/reset/">※パスワードをお忘れの方</a></p>
            <p><a href="/login/ID/" class="txt_red">※既にハガキや旧サイトで製品登録済で<br>オーナー番号をお持ちの方</a></p>
        </div>
</ValidationObserver>
      </form>
    </div>
    <div class="item_login">
      <div class="btn"> <a href="/login/customer/register/email" class="arrow arrow_g">新規会員登録</a> </div>
    </div>
		  </div>
  </section>
</main>
</template>

<script>
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import Axios from 'axios'
import String from '../string'

extend('email-required', {
  ...required,
  message: 'メールアドレスを正しく入力してください'
})

extend('email-email', {
  ...email,
  message: 'メールアドレスを正しく入力してください'
})

extend('password-required', {
  ...required,
  message: 'パスワードを正しく入力してください'
})

export default {
  data: function () {
    return {
      globalErrorMessage: ''
    }
  },
  mixins: [String],
  methods: {
    submit: function () {
      this.initGlobalErrorMessage()
      this.$refs.observer.validate()
        .then((res) => {
          if (res) {
            var data = {
              email: this.$root.$children[0].form.email,
              password: this.$root.$children[0].form.password
            }
      
            this.$root.$children[0].$set(this.$root.$children[0].$data, 'isLoading', true)
      
            Axios.post('/api/login', data)
              .then((res) => {
                if (res.data.status == 'success') {
                  setTimeout(() => {
                    location.href = '/login/top'
                  }), 1000
                }
                else {
                  this.$set(this.$data, 'globalErrorMessage', 'メールアドレスまたはパスワードが正しくありません')
                }
      
                this.$root.$children[0].$set(this.$root.$children[0].$data, 'isLoading', false)
              }
            )
        }
      })
    },
    initGlobalErrorMessage: function () {
       this.$set(this.$data, 'globalErrorMessage', '')      
    },
    processSubValidatedEmail: function () {
      this.$root.$children[0].$set(this.$root.$children[0].form, 'email', this.subValidatedEmail(this.$root.$children[0].form.email))
    }
  },
  computed: {
    errorMessage: function () {
      if (this.$refs.observer['flags']['invalid']) {
        var k = ''

        for (k in this.$refs.observer['errors']) {
          if (this.$refs.observer['errors'][k][0]) {
            return this.$refs.observer['errors'][k][0]
          }
        }
      }
      else if (this.globalErrorMessage) {
        return this.globalErrorMessage
      }
      else {
        return ''
      }
    }
  }
}
</script>
